.decription {
  @apply rounded-md border-[1px] border-gray-200;
}

.decription .decription-row {
  @apply grid grid-cols-12;
}

.decription .decription-row:not(:last-of-type) {
  @apply border-b-[1px] border-gray-200;
}

.description-row-label,
.descirption-row-content {
  @apply px-4 py-2;
}

.description-row-label {
  @apply col-span-3 flex items-center border-r-[1px] border-gray-200 bg-gray-50;
}

.descirption-row-content {
  @apply col-span-9;
}

@media only screen and (max-width: 768px) {
  .decription .decription-row {
    @apply block;
  }
  .description-row-label {
    @apply border-r-0 border-b-[1px] text-sm;
  }
}
