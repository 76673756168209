@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full;
}

.path-primary {
  color: #b60c51;
}
