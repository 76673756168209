.contacts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 12px;
  grid-template-rows: auto;
}

.contacts-grid a {
  @apply underline underline-offset-1;
}
